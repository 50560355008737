import React from "react";
import * as TUI from "theme-ui";

const Root = TUI.Grid;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {}

function SectionContainer(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { sx, ...rest } = props;

  return (
    <Root
      ref={ref}
      {...rest}
      columns={[
        "[el] 16px [fl sl ml] 1fr [mr sr fr] 16px [er]",
        null,
        "[el] 16px [fl sl] 1fr [sr] 24px [ml] 2fr [mr fr] 16px [er]",
        "[el] 24px 1fr [fl sl] 368px [sr] 48px [ml] minmax(min-content, 736px) [mr fr] 1fr 24px [er]",
      ]}
      sx={{
        gridAutoFlow: "row",
        columnGap: 0,
        rowGap: 5,
        ...sx,
      }}
    />
  );
}

/**
 * Constants for use in CSS `grid-column`.
 */
export const column = {
  main: "ml / mr",
  side: "sl / sr",
  full: "fl / fr",
  edge: "el / er",
} as const;

export default React.forwardRef(SectionContainer);
