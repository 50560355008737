import { useMeasure } from "@/hooks";
import * as Sentry from "@/sentry";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import * as TUI from "theme-ui";
import { column } from "../SectionContainer";

interface Props {
  variant?: string;
  children?: React.ReactChild | null;
  allowBreaks?: boolean;
}

const MDX = dynamic<$FixMe>(() => import("./MDX"), {
  loading: () => <Spinner />,
  ssr: false,
});

export default function Markdown(props: Props) {
  const { variant = "markdown.main", children, allowBreaks } = props;

  return (
    <TUI.Box
      variant={variant}
      data-md-wrapper
      sx={{
        "& > br": {
          display: allowBreaks ? "block" : "none",
        },
      }}
    >
      {(() => {
        if (typeof children === "string") {
          const s = children
            .replace(/class=/g, "className=")
            .replace(/for=/g, "htmlFor=")
            .replace(/onload=/gi, "onLoad=")
            .replace(/referrerpolicy=/gi, "referrerPolicy=")
            .replace(/allowfullscreen=/gi, "allowFullScreen=")
            .replace(/<a /g, "<h.a ")
            .replace(/<\/a>/g, "</h.a>");

          return (
            <ErrorBoundary markdown={children}>
              <MDX>{s}</MDX>
            </ErrorBoundary>
          );
        } else {
          return children;
        }
      })()}
    </TUI.Box>
  );
}

export function Footnotes() {
  const [visible, setVisible] = useState(false);
  const [sectionRef, { height }] = useMeasure<HTMLParagraphElement>();

  useEffect(() => {
    if (height >= 20) {
      setVisible(true);
    }
  }, [height]);

  return (
    <TUI.Box
      sx={{
        visibility: visible ? "visible" : "hidden",
        overflow: "hidden",
        color: "grayDark",
        borderTop: "1px solid currentColor",
        borderColor: "grayMedium",
        gridColumn: column.main,
        px: [2, 0],
        py: visible ? 4 : 0,
        mb: visible ? 4 : 0,
        bg: "white",
        hr: { display: "none" },
        a: {
          ml: 1,
          fontFamily: "monospace",
        },
        ol: {
          m: 0,
          paddingInlineStart: "4.5em",
          listStyleType: "none",
          li: {
            position: "relative",
          },
          "& li:before": {
            content: '"—"',
            position: "absolute",
            left: "-1.5em",
          },
          "& li:after": {
            content: "attr(data-footnote-id)",
            fontStyle: "italic",
            mx: "0.5em",
            opacity: 0.6,
          },
        },
      }}
    >
      <TUI.Text ref={sectionRef} variant="f7" id="footnoteportal" />
    </TUI.Box>
  );
}

export const Spinner = () => {
  return (
    <TUI.Flex sx={{ justifyContent: "center", alignItems: "center", opacity: 0.5 }}>
      {[0, 0.3, 0.6].map((d) => {
        return (
          <motion.div
            key={d}
            animate={{ opacity: [0, 1], y: 5 }}
            transition={{ yoyo: Infinity, duration: 1.2, delay: d }}
          >
            •
          </motion.div>
        );
      })}
    </TUI.Flex>
  );
};

class ErrorBoundary extends React.Component<{ markdown: string }> {
  state: { hasError: boolean };

  constructor(props: $FixMe) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: $FixMe, errorInfo: $FixMe) {
    console.log(error, errorInfo);
    Sentry.withScope((scope) => {
      scope.setExtra("markdown", this.props.markdown);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <TUI.Box sx={{ p: 3, my: 3 }}>😞</TUI.Box>;
    }

    return this.props.children;
  }
}
