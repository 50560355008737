import { useEffect, useState, useRef } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

export const useMeasure = <T extends Element>() => {
  const roRef = useRef<ResizeObserver>();
  const elRef = useRef<T>(null);
  const [width, changeWidth] = useState(1);
  const [height, changeHeight] = useState(1);
  const [left, changeLeft] = useState(1);
  const [right, changeRight] = useState(1);

  useEffect(() => {
    if (!elRef.current) {
      return;
    }

    if (!roRef.current) {
      roRef.current = new ResizeObserver(entries => {
        // Since we only observe the one element, we don't need to loop over the
        // array
        if (!entries.length) {
          return;
        }

        const entry = entries[0];

        const { inlineSize: w, blockSize: h } = entry.contentBoxSize[0];

        const { left, right } = entry.target.getBoundingClientRect();

        changeWidth(w);
        changeHeight(h);
        changeLeft(left);
        changeRight(right);
      });
    }

    roRef.current.observe(elRef.current);

    return () => {
      roRef.current?.disconnect();
    };
  }, []);

  return [elRef, { width, height, left, right }] as const;
};
