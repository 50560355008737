import * as GQL from "@/gql";

export const cookieConsent: GQL.CookieConsentQuery["cookieConsent"] = {
  version: "1",
  _allInfoLocales: [
    {
      locale: "de",
      value:
        "Diese Website verwendet Cookies, um eine reibungslose Benutzererfahrung sicherzustellen. [Erfahren Sie mehr](/de/datenschutz).",
    },
    {
      locale: "en",
      value:
        "This website uses cookies to ensure a smooth user experience. [Learn more](/en/data-privacy).",
    },
    {
      locale: "fr",
      value:
        "Ce site web utilise des cookies pour assurer une expérience de navigation optimale. [Pour en savoir plus](/fr/prot-donnees).",
    },
    {
      locale: "it",
      value:
        "Utilizziamo i Cookie per garantire la migliore esperienza di navigazione sul nostro sito web. [Per saperne di più](/it/protezione-dei-dati).",
    },
  ],
  _allAcceptLocales: [
    { locale: "de", value: "Einverstanden" },
    { locale: "en", value: "Agree" },
    { locale: "fr", value: "Acceptation" },
    { locale: "it", value: "Accetta" },
  ],
} as any;
